import { BASE_MANAGE_SUB_LINK } from '../shared/stripeConfig'

const StripeManageSubscriptionLink = ({userEmail, text, button = false}) => {
    const manageSubscriptionLink = `${BASE_MANAGE_SUB_LINK}?prefilled_email=${userEmail}`

    return (
        <a target="_blank" rel="noreferrer" href={manageSubscriptionLink}>
        { 
            button ?
                <button className="manageSubscription">
                    {text}
                </button> 
            : 
                <>{text}</>
        }
        </a>
    )
}

export default StripeManageSubscriptionLink
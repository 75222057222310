import { db } from '../../shared/firebase'
import { doc, getDoc } from 'firebase/firestore'

export const checkSubscription = async (uid) => {
        const userDocRef = doc(db, "users", uid)
        const userDoc = await getDoc(userDocRef)
        const userData = userDoc.data()

        if (userDoc.exists()) 
            return userData.subscribed

        return false
}

export default checkSubscription
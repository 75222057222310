// import React, { useEffect, useState } from 'react'
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  createTheme, ThemeProvider, CssBaseline, AppBar, Toolbar, Button, Container, Typography, Grid, Box,
  useTheme, Drawer, List, ListItem, ListItemText, IconButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { MuiList, MuiListItem } from './MuiList';

import { useMediaQuery } from '@mui/material'
import { auth, db } from './components/shared/firebase'
import { doc, setDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { checkSubscription } from './components/dashboard/utility/condenser'
import Library from './components/dashboard/Library'
import Help from './components/dashboard/Help'
import Account from './components/dashboard/Account'
import Highlights from './components/dashboard/Highlights'
import Success from './components/dashboard/Success'
import Letter from './Letter';
import "./App.css"


const Home = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  return (
    <Container>
      <Typography variant="h3" sx={{fontSize: '2em', fontWeight: '700'}}>Filter Today's Financial News Through AI To Pull Out Only Concrete Facts</Typography><br />
      <Box component='img' src={`/${prefersDarkMode ? 'Pasted image 20240313004430.webp' : 'Pasted image 20240313004411.webp'}`} sx={{ width: 1/2, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px'}} />
      

      <MuiList listType='none' textAlign='center'>
        <MuiListItem variant="h4">No Distractions or Filler</MuiListItem>
        <MuiListItem variant="h4">Highlight, Take Notes, and Export</MuiListItem>
        <MuiListItem variant="h4">Save Hundreds of Hours Per Year Without Skipping Over Important Details</MuiListItem>
      </MuiList>
      <Grid
        container
        spacing={ 0 }
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={ 3 }>
          <Button variant="contained" component={ Link } to="/letter" onClick={()=>window.scrollTo(0,0)}>Learn More</Button>
        </Grid>
      </Grid>
    </Container>
  );
}

const About = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  return (
    <Container>
      <Box component='img' src={`/twofive_logo_text_${prefersDarkMode ? 'dark' : 'light'}.webp`} sx={{ width: 1/2, margin: 'auto', display: 'block'}} />
      <Typography variant="h4">About Condenser</Typography>
      <Typography>
        Condenser is a tool published by TwoFive Labs, built to help people who spend many hours each week reading US financial news such as investors, traders, writers and researchers. Condenser filters out unnecessary parts of financial news articles, presenting only the most relevant, objective facts, while avoiding the pitfalls of typical generated summaries.
      </Typography>

      <Typography><Link to="/letter">Click here</Link> to learn more about Condenser.</Typography>

      <Typography variant="h4">How to Contact Us</Typography>
      <Typography>
        You can reach us by email at support@twofivelabs.com or through <Link to="https://contact.twofivelabs.com" target="_blank" rel="noopener noreferrer">our support ticket system</Link>.
      </Typography>

      <Typography variant="h4">Mailing Address</Typography>
      <Typography>
        TwoFive Labs LLC<br />
        21750 Hardy Oak Blvd<br />
        Ste 104 PMB 915816<br />
        San Antonio, Texas 78258<br />
        USA
      </Typography>

      <Typography variant="h4">Our Refund Policy</Typography>
      <Typography>
        Any product we offer comes with a 60-day money-back guarantee. If you've purchased one of our products in the last 60 days and have found it's not for you, simply contact us by email or support ticket and we will refund you in full.
      </Typography>

      <Typography variant="h4">Our Privacy Policy</Typography>
      <Typography><Link to="/privacy">Click here</Link> to view our Privacy Policy.</Typography>

      <Typography centerBody></Typography>
      <Typography centerBody></Typography>
    </Container>
  );
}

const Privacy = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch('/privacy.html')
        .then(response => response.text())
        .then(html => setData(html));
  }, []);

  return (
    <Container>
      <div dangerouslySetInnerHTML={{__html: data}} />
    </Container>
  );
}

const Footer = () => (
  <Box sx={{ height: '200px', mt: '20px' }}>
  </Box>
);

const Nav = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSignIn = async (callback = null) => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          name: user.displayName,
          email: user.email,
          subscribed: false,
        });
      }
      if (callback) callback();
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  const redirectToIndex = () => { navigate("/") };

  const drawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'Condenser', path: '/letter' },
    { text: 'About', path: '/about' },
    { text: 'Privacy', path: '/privacy' },
    { text: 'Sign In', action: () => handleSignIn(() => navigate("/")) },
  ];

  const renderMobileMenu = () => (
    <Drawer anchor="left" open={drawerOpen} onClose={drawerToggle}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} onClick={() => {item.path ? navigate(item.path) : item.action(); drawerToggle();}}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  const renderDesktopMenu = () => (
    <Toolbar>
      {menuItems.slice(0, -1).map((item, index) => (
        <Button key={index} color="inherit" component={Link} to={item.path}>{item.text}</Button>
      ))}
      <Button color="inherit" onClick={menuItems[menuItems.length - 1].action}>{menuItems[menuItems.length - 1].text}</Button>
    </Toolbar>
  );

  return (
    <AppBar position="static" style={{ marginBottom: '20px' }}>
      {isMobile ? (
        <>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={drawerToggle}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
          {renderMobileMenu()}
        </>
      ) : renderDesktopMenu()}
    </AppBar>
  );
};

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignIn = async (callback = null) => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        // If user does not exist, save them to the database with the 'owner' field
        await setDoc(userDocRef, {
          name: user.displayName,
          email: user.email,
          subscribed: false,
        });
      }
      if (callback) callback();
    } catch (error) {

    }
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: prefersDarkMode
          ? {
              main: '#222222',
            }
          : {
              main: '#000000', 
            },
        },

        typography: {
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
        },
        components: {
          MuiTypography: {
            styleOverrides: {
              root: {
                maxWidth: '35em',
                textAlign: 'start',
                margin: '0 auto',
                textAlign: 'left',
              },
              h1: {
                maxWidth: '12em',
                textAlign: 'left',
              },
              h2: {
                maxWidth: '12em',
                textAlign: 'left',
              },
              h3: {
                maxWidth: '12em',
                textAlign: 'left',
              },
              h4: {
                maxWidth: '17em',
                textAlign: 'left',
              },
              h5: {
                maxWidth: '12em',
                textAlign: 'left',
              },
              h6: {
                maxWidth: '12em',
                textAlign: 'left',
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  )

  const [user, setUser] = useState(null)
  const [doneLoading, setDoneLoading] = useState(false)
  const [isSubbed, setIsSubbed] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        checkSubscription(firebaseUser.uid).then(subCheck => {
          setIsSubbed(subCheck);
          setDoneLoading(true); 
        });
      } else {
        setUser(null);
        setIsSubbed(false); 
        setDoneLoading(true);
      }
    });

    return () => unsubscribe();
  }, []);


  const SubbedRoutes = () => (
    <Routes>
      <Route path="/" element={ user ? <Library user={user} /> : <Home /> } />
      <Route path="/about" element={ <About /> } />
      <Route path="/help" element={ <Help /> } />
      <Route path="/account" element={ <Account /> } />
      <Route path="/highlights" element={ <Highlights /> } />
      <Route path="/success" element={ <Success /> } />
      <Route path="/letter" element={ <Letter user={user} handleSignIn={handleSignIn} navigate={navigate} /> } />
      <Route path="/privacy" element={ <Privacy /> } />
      <Route path="/learnmore" element={ <Home /> } />

      <Route path="/:book_id" element={ <Library user={user} /> } />
    </Routes>
  )

  const UnSubbedRoutes = () => (
    <Routes>
      <Route path="/about" element={ <About /> } />
      <Route path="/letter" element={ <Letter user={user} handleSignIn={handleSignIn} navigate={navigate} /> } />
      <Route path="/privacy" element={ <Privacy /> } />
      <Route path="*" element={ user ? <Account /> : <Home /> } />
    </Routes>
  )

  if (doneLoading) {
    return (
      <ThemeProvider theme={ theme }>
        { !isSubbed && !user && <CssBaseline /> }
        { (!user) && <Nav /> }
        { isSubbed ? <SubbedRoutes /> : <UnSubbedRoutes /> }
        { ((!user) || (location.pathname === '/letter')) && <Footer /> }
      </ThemeProvider>
    );
  }
}

export default App

import React from 'react';
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { MuiList, MuiListItem } from './MuiList';
import { useMediaQuery } from '@mui/material';

const LetterDark = ({user, handleSignIn, navigate}) => {
    return (
            <Container>
            <Typography variant="h3">How to Save 200 Hours This Year With Our Stock Market News AI</Typography>
            <Typography>
<b>If you consume American financial news on a weekly basis</b>, the tool on this page could help you consume that news ten times faster, while retaining far more of what you read. Without traditional "summaries". And without sacrificing any core knowledge.</Typography>

<Typography><Typography variant="h4">Get Through Your Financial News 10x Faster Without Compromising Knowledge</Typography>

This is a typical financial news article from a major national website, with hundreds of millions of users. </Typography>

<Typography>It's blurred to protect the source.</Typography>

<Typography>It's about a failed merger between JetBlue and Spirit Airlines.</Typography>

<Typography><Box component='img' src='/uncolored2.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>
We analyzed this page.</Typography>

<Typography><b>Over 90% of the page</b> is content that most investors and traders do need at all.</Typography>

<Typography><Box component='img' src='/yellow2.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>We highlighted everything that was useless for the typical market news reader.</Typography>

<Typography>This includes:<br /></Typography><MuiList>
<MuiListItem>Biased quotes like the CEO calling the merge a "bold and courageous plan"</MuiListItem><MuiListItem>Filler like "JetBlue didn't immediately comment"</MuiListItem><MuiListItem>Extra details about legal procedures or history</MuiListItem><MuiListItem>All sorts of unrelated links, distractions, and clickbait</MuiListItem><MuiListItem>Flashing lights, pictures, ads</MuiListItem>
</MuiList>
<Typography>None of that moves markets.</Typography>

<Typography>We found the best facts were scattered throughout, and took up just 9% of the page. We've marked those here in green.</Typography>

<Typography><Box component='img' src='/green2.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>The green blocks are hyper-specific facts like the amount of money involved in the deal or the fact that Spirit must pay a $69 million fee due to the termination.</Typography>

<Typography>In other words the green is what you actually want. Everything else on the page is a waste of your time.</Typography>

<Typography>We've developed a tool that <b>pulls out just those green, important parts and delivers them straight to you, every trading day</b>.</Typography>

<Typography>We call it <b>Condenser</b>.</Typography>

<Typography><Typography variant="h4">Introducing Condenser by TwoFive Labs</Typography>

Condenser pulls the most market-relevant facts, wherever they are in an article, and condenses them into a single clean view.</Typography>

<Typography>This is how the news above appears in Condenser.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313004054.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>In the example above, we pulled out <b>just the 9% of the page you actually want</b> - the part that is relevant to financial markets.</Typography>

<Typography>We cut out the entire 89% of the page that is just a waste of your time.</Typography>

<Typography><Typography variant="h4">Condenser vs ChatGPT's Smartest Model</Typography>

We asked ChatGPT to summarize the same article above.</Typography>

<Typography>Keep in mind we used the smartest version of ChatGPT available to the public. It costs $240 per year to use.</Typography>

<Typography>This is what it produced.</Typography>

<Typography><Box component='img' src='/Pasted image 20240315211138.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>You'll notice some core problems with ChatGPT's summary compared to ours.<br /></Typography><MuiList>
<MuiListItem>ChatGPT completely misses critical tangents, like billionaire Carl Icahn's 10% stake in JetBlue</MuiListItem><MuiListItem>ChatGPT's summary misses almost all specific numbers</MuiListItem><MuiListItem>ChatGPT's summary is packed together, hard to read</MuiListItem><MuiListItem>It's 20% longer</MuiListItem><MuiListItem>No easy way to save facts for later</MuiListItem><MuiListItem>Have to manually input text</MuiListItem>
</MuiList>
<Typography>If you're trying to use ChatGPT to save time reading financial news, our tests show you'll see much better results with Condenser.<br /></Typography><MuiList>
<MuiListItem>Unlike other AI tools, Condenser is built to achieve one goal: pull key facts from financial articles. It scans for <b>anything</b> that could affect the market</MuiListItem><MuiListItem>Condenser lets you save facts for later and take notes. You can export saved facts to Excel, Word, and more</MuiListItem><MuiListItem>You don't have to submit articles or paste text. All the latest market news is pre-loaded in our app every trading day and streamlined by our AI</MuiListItem>
</MuiList>
<Typography>On this page we'd like to show you why Condenser could allow you to consume your financial news 10 times faster than reading full articles, without resorting to traditional "summaries", and without missing any important details.</Typography>

<Typography>And then we'll show you how you can get started using it right away on your phone, laptop, tablet, or almost any device with an internet connection.</Typography>

<Typography><Typography variant="h4">What It's Like to Get Your Financial News From Condenser</Typography>

Each day when you open Condenser, the first page you see is your feed. This contains the day's latest financial news, updated every trading day. You can see it's sorted into two tabs, unread and read.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313000127.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>When you click on an article, Condenser shows you this.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313000207.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>You'll notice a short background paragraph at the top to get you up to speed.</Typography>

<Typography>And then, our main focus: a nice, clean list of the specific facts contained within the news story. </Typography>

<Typography><Box component='img' src='/Pasted image 20240313000207.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>Unlike primitive summary tools, Condenser is capable of finding useful side facts buried in articles where you would least expect them.</Typography>

<Typography><Typography variant="h4">Condenser Spots Useful “Side Information” Most Basic Summary Tools Miss</Typography>

For example, when reporting on JetBlue and Spirit’s merger failing, Condenser decided it was important to mention that Carl Icahn owns 10% of JetBlue. </Typography>

<Typography><Box component='img' src='/Pasted image 20240313215034.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>This is an important part of what makes Condenser unique.</Typography>

<Typography>Carl Ichan is one of the world's most powerful billionaires and has a long history of success as an investor.</Typography>

<Typography>The fact that he owns so much of Jetblue is <b>highly</b> relevant to the overall market. </Typography>

<Typography>But this fact is only <b>slightly</b> relevant to the main article.</Typography>

<Typography>So most “summary” tools, including the default ChatGPT, would skip over this information entirely.  </Typography>

<Typography>This demonstrates how Condenser helps you find the information you really want - even if it's buried in a tangent in the middle of the page.</Typography>

<Typography><Typography variant="h4">Don't Just See Key Facts. Save Them.</Typography>

Condenser doesn't just show you the facts of each article. When you see facts you may find useful later, Condenser allows you to save them. Just tap or click on a fact twice to highlight.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313002210.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>You can then add tags to your highlights.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313002246.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>Existing tags show up as small labels on the bottom left, like a real-life tag.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313002325.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>You can add custom notes to your highlights as well.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313002352.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>And then you can review your highlights any time on your Highlights page.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313002415.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>As you can see in that image, you can review all your highlights right on Condenser.</Typography>

<Typography>You can filter by tag. </Typography>

<Typography><Box component='img' src='/Pasted image 20240313002951.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /><br />And you can easily see the source of your highlight linked at the bottom. Just click that link to go back to where you made the highlight.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313003127.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>But you don't have to keep your saved highlights on our site.</Typography>

<Typography>You can export them to outside platforms too.</Typography>

<Typography><Box component='img' src='/Pasted image 20240313003250.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography>And by the way, if you really want more detail on any given article, no problem.</Typography>

<Typography>Just click "Source" at the top of the article page to read the full text of the original. </Typography>

<Typography><Box component='img' src='/Pasted image 20240313003104.webp' sx={{ width: 1/1, margin: 'auto', display: 'block', boxShadow: '0 0 10px #aaaaaa', borderRadius: '4px' }} /></Typography>

<Typography><Typography variant="h4">Use Condenser On Your Laptop, Phone, Tablet - Just About Anywhere</Typography>

Condenser lives on the web so you can use it from almost any device, phone, tablet, or computer.</Typography>

<Typography>Your library, notes, and highlights sync across all devices.</Typography>

<Typography><Typography variant="h4">How Much Does Condenser Cost?</Typography>

Condenser costs $8 per month with our yearly plan.</Typography>

<Typography>Compare that to ChatGPT's best version which costs $20 per month, or $240 per year. </Typography>

<Typography>And ChatGPT doesn't deliver the latest news straight to your feed, it doesn't let you highlight and take notes, and it completely misses important market facts. </Typography>

<Typography>Condenser is tailored to your needs. And it's 60% cheaper than ChatGPT.</Typography>

<Typography><Typography variant="h4">Is It Really Worth It?</Typography>

<b>Do you read financial news multiple days per week?</b></Typography>

<Typography>If so...</Typography>

<Typography><b>The federal minimum wage is $7.25 per hour. Is your time is worth at least that much?</b></Typography>

<Typography>If the answer to both of those questions is yes, we believe adding Condenser to your life is worth many, many times more than the cost of the subscription.</Typography>

<Typography><b>Saving even 1 hour per month makes your subscription more than worth it.</b></Typography>

<Typography>You could easily save over 200 hours per year using Condenser. That equals $120 <b>per month</b> of minimum wage work. Yet Condenser costs just $8 per month.</Typography>

<Typography>The more financial news you typically consume, the larger the potential value. </Typography>

<Typography>And if you spend significant time taking and organzing notes as you read, the time saved increases even further with Condenser's powerful highlight, note, and tag features.</Typography>

<Typography><Typography variant="h4">To Be Sure, Try Condenser For 60 Days</Typography>

When you begin your subscription, you have 60 days to try Condenser. If it's not for you, at any point in those first 60 days, just reach out to us by email or support ticket and we will refund you in full.</Typography>

<Typography>Click the button below to get started.</Typography>


            <Typography>{'\n\n'}</Typography>
            <Box sx={{ width: 1/2, margin: 'auto', display: 'block' }}>
            { 
                user ?
                    <Button variant="contained" sx={{ width: 1/1, margin: 'auto', display: 'block', textAlign: 'center' }} component={ Link } to="/account" onClick={()=>window.scrollTo(0,0)}>Start Using Condenser</Button>
                :
                    <Button variant="contained" sx={{ width: 1/1, margin: 'auto', display: 'block' }} onClick={() => handleSignIn(() => {navigate("/")})}>Start Using Condenser</Button>
            }
            </Box>
            </Container>
        )
}

export default LetterDark

// src/components/dashboard/Dashboard.js

import React, { useState, useEffect, useRef } from 'react'
import { auth, db } from '../../components/shared/firebase'
import { doc, getDoc } from "firebase/firestore"
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import StripeManageSubscriptionLink from './StripeManageSubscriptionLink'
import { checkSubscription } from './utility/condenser'
import { StaticWrapper, StaticSection, StaticNote } from './utility/statictext/statictext'
import "../../assets/styles/Dashboard.css"
import "../../assets/styles/Account.css"
import "../../assets/styles/Static.css"
import "../../assets/styles/Help.css"


const Help = () => {

  const [user, setUser] = useState(null)
  const [userID, setUserID] = useState(null)
  const [menuVisible, setMenuVisible] = useState(false)
  const [subActive, setSubActive] = useState(false)

  const navigate = useNavigate();

  const menuRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in
        setUser(firebaseUser)
        setUserID(firebaseUser.uid)
  
        checkSubscription(firebaseUser.uid).then(subCheck => {
          setSubActive(subCheck)
        })
      } else {
        // User is signed out
        setUser(null);
        setUserID(null);
        setSubActive(false); // Ensure subscription status is reset when user signs out
      }
    });
  

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    if (!userID) return;

    const getUserActiveSub = async () => {
      try {
        const userDocRef = doc(db, "users", userID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.subscriptionActive) {
            setSubActive(true);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserActiveSub();
  }, [userID]);


  const handleLogout = () => {
    setTimeout(() => {
      signOut(auth)
        .then(() => navigate("/"))
        .catch((error) => console.error("Logout error:", error));
    }, 100); // Delay logout by 100ms
  }


  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  }

  const handleDashboardClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  }

  return (
    <div className="dashboardContainer accountContainer helpContainer" onClick={ handleDashboardClick }>
    {subActive ? <><div className="user-top-menu-bar"></div>
    <div className="userMenu">
        <div className={ menuVisible ? "menuBarContainer activeMenu" : "menuBarContainer" } onClick={ handleMenuClick }>
          <div className="menuBar"></div>
          <div className="menuBar"></div>
          <div className="menuBar"></div>
        </div>

      { menuVisible ? (
        <div ref={ menuRef } className="floatingMenuContainer">
          <div className="floatingMenuItem" onClick={ () => { navigate("/"); setMenuVisible(false) } }>Library</div>
          <div className="floatingMenuItem" onClick={ () => { navigate("/highlights"); setMenuVisible(false) } }>Highlights</div>
          <div className="floatingMenuItem subscription" onClick={ () => { navigate("/account"); setMenuVisible(false) } }>Manage Subscription</div>
          <div className="floatingMenuItem active-page" onClick={ () => { navigate("/help"); setMenuVisible(false) } }>Help</div>
          <div className="floatingMenuItem logout" onClick={ handleLogout }>Logout</div>
        </div>
      ) : "" }
    </div></> : "" }
      <StaticWrapper header="Help">
        <StaticSection header="Managing Your Subscription">
          You can manage your subscription directly through our payment processor, Stripe. Stripe provides a full-featured dashboard through which you may adjust or cancel your subscription, change your payment details, and more. {user ? <StripeManageSubscriptionLink userEmail={user.email} text="Click here to access your subscription dashboard" /> : "Click here to access your subscription dashboard"}.
          <StaticNote header="About the Subscription Dashboard">
            When you click that link, you should see your email pre-filled in a box with a prompt to continue. Stripe will email you a login link. Click that link to access your dashboard.
          </StaticNote>
        </StaticSection>
        <StaticSection header="Support">
          If you have questions, you can reach us at <strong>support@twofivelabs.com</strong>. If you have trouble reaching us by email for any reason, you may also open a support ticket through our help desk. <a rel="noreferrer" target="_blank" href="https://contact.twofivelabs.com">Click here to create a new ticket</a>.
        </StaticSection>
      </StaticWrapper>
    </div>
  )
}

export default Help

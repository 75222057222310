// src/components/dashboard/Dashboard.js

import React, { useState, useEffect, useRef } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { auth, db } from '../../components/shared/firebase';
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { checkSubscription } from './utility/condenser';
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Account.css";

const Success = () => {

  const [user, setUser] = useState(null); // Replace with actual auth logic
  const [userID, setUserID] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [gotSubInfo, setGotSubInfo] = useState(false)

  const navigate = useNavigate();

  const menuRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in
        setUser(firebaseUser);
        setUserID(firebaseUser.uid);
  
        checkSubscription(firebaseUser.uid).then(subCheck => {
          setSubActive(subCheck)
          setGotSubInfo(true)
        })
      } else {
        // User is signed out
        setUser(null);
        setUserID(null);
        setSubActive(false); // Ensure subscription status is reset when user signs out
      }
    });
  

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!userID) return;

    const getUserActiveSub = async () => {
      try {
        const userDocRef = doc(db, "users", userID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.subscriptionActive) {
            setSubActive(true);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserActiveSub();
  }, [userID]);


  const handleLogout = () => {
    setTimeout(() => {
      signOut(auth)
        .then(() => navigate("/"))
        .catch((error) => console.error("Logout error:", error));
    }, 100); // Delay logout by 100ms
  };


  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleDashboardClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  const loadComponent = () => (
    <div className="successContainer" onClick={ handleDashboardClick }>
    {subActive ? <><div className="user-top-menu-bar"></div>
    <div className="userMenu">
        <div className={ menuVisible ? "menuBarContainer activeMenu" : "menuBarContainer" } onClick={ handleMenuClick }>
          <div className="menuBar"></div>
          <div className="menuBar"></div>
          <div className="menuBar"></div>
        </div>

      { menuVisible ? (
        <div ref={ menuRef } className="floatingMenuContainer">
          <div className="floatingMenuItem" onClick={ () => { navigate("/"); setMenuVisible(false) } }>Library</div>
          <div className="floatingMenuItem" onClick={ () => { navigate("/highlights"); setMenuVisible(false) } }>Highlights</div>
          <div className="floatingMenuItem subscription" onClick={ () => { navigate("/account"); setMenuVisible(false) } }>Manage Subscription</div>
          <div className="floatingMenuItem" onClick={ () => { navigate("/help"); setMenuVisible(false) } }>Help</div>
          <div className="floatingMenuItem logout" onClick={ handleLogout }>Logout</div>
        </div>
      ) : "" }
    </div></> : "" }
      <div className="successContainer">
        <div className="currentSubscription">
          <div className="subActiveText">{subActive &&
            <div className="subActiveSection">
            <div className="subStatusWrapper">
            <div className="yourSubIs">
            Subscription Confirmed for <strong>{user.email}</strong></div><br />
              <div className="activeText welcomeHeader">Welcome to Condenser</div>
              <div className="textBlock">
              <p className="bodyText subBodyHead">
                You now have full access to the platform. Here is how to get started. 
              </p>
              <p className="bodyText">
                <strong>Step 1.</strong> Review your <Link to="/">Library</Link> homepage to view today's latest financial news. We scan the news daily and extract just the concrete facts from each article.
              </p>
              <p className="bodyText">
              <strong>Step 2.</strong> Highlight any facts that you want to save for later. Add tags or notes.
            </p>
            <p className="bodyText">
            <strong>Step 3.</strong> Review or export your facts on your <Link to="/highlights">Highlights</Link> page.
          </p>
          <p className="bodyText">
          <strong>Step 4.</strong> Use the menu in the top left of the page to navigate through the app and <Link to="/account">manage your account</Link>.
        </p>
        <p className="bodyText">
        <strong>Step 5.</strong> If you run into any trouble, we're one email away. Contact us at <strong>support@twofivelabs.com</strong> and a member of our team will assist you as soon as possible.
      </p>
      <p className="bodyText">
      Thank you for choosing Condenser. We expect this will be the most efficient financial news reading experience you've ever experienced. Happy reading.
    </p>
    <p className="bodyText">
    <div className="loadMoreBtnWrapper"><Link to="/"><button className="load-more-btn success-home-btn">Click Here to Go to Your Homepage</button></Link></div></p>
              </div>
              </div>
            </div>}</div>
        </div>
      </div>
    </div>
  )

  return (
    gotSubInfo && loadComponent()
  )
}

export default Success;

import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { db } from '../shared/firebase';
import { getDoc, doc, setDoc, query, collection, where, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import 'reactjs-popup/dist/index.css';
import "../../assets/styles/LibraryTagManager.css";

const LibraryTagManager = ({ userID, bookID, handleBookDelete }) => {
    const [tagList, setTagList] = useState([]);
    const [deleteBookConfirm, setDeleteBookConfirm] = useState(false);
    const [tagInput, setTagInput] = useState("");
    // useEffect(() => {
    //     const loadTags = async () => {
    //         setTagList([]);

    //         // Directly reference the specific book document by its ID
    //         const bookRef = doc(db, "userLibrary", bookID);

    //         try {
    //             const docSnap = await getDoc(bookRef);
    //             if (docSnap.exists()) {
    //                 const data = docSnap.data();
    //                 if (data.tags && data.tags.length > 0) {
    //                     // Removing duplicates if any and setting tags
    //                     const uniqueTags = [...new Set(data.tags)];
    //                     setTagList(uniqueTags);
    //                 }
    //             } else {
    //                 console.log("No matching book found");
    //             }
    //         } catch (error) {
    //             console.error("Error loading tags: ", error);
    //         }
    //     };

    //     loadTags();
    // }, [userID, bookID]);

    const handleTagAdd = async (e) => {
        if (tagInput !== "") {
            // Reference the specific book document directly by its ID
            const bookRef = doc(db, "userLibrary", bookID);

            try {
                const docSnap = await getDoc(bookRef);
                if (docSnap.exists()) {
                    // Update the book document
                    await updateDoc(bookRef, {
                        tags: arrayUnion(tagInput.trim().toUpperCase())
                    });
                } else {
                    console.log("No matching book found");
                }

                // Check if the tag already exists in the tags collection
                const tagsCollection = collection(db, "tags");
                const tagQuery = query(tagsCollection, where("name", "==", tagInput.trim().toUpperCase()), where("owner", "==", userID));
                const tagQuerySnapshot = await getDocs(tagQuery);

                // If the tag doesn't exist, add it to the tags collection
                if (tagQuerySnapshot.empty) {
                    const newTagRef = doc(tagsCollection);
                    await setDoc(newTagRef, {
                        name: tagInput.trim().toUpperCase(),
                        owner: userID
                    });
                }

                setTagList(prev => [...prev, tagInput.trim().toUpperCase()]);
                setTagInput("");
            } catch (error) {
                console.error("Error updating document:", error);
            }
        }
    };

    const showTags = () => {
        const viewWidth = window.innerWidth;
        let tagsToShow = [];
        let currentLength = 0;
        let additionalTagsCount = 0;
        let displayNum = "";

        if (viewWidth < 295) {
            displayNum = (tagList.length > 0) ? tagList.length : false;
            tagsToShow = displayNum ? [displayNum] : [];
        }
        else if (viewWidth < 414) {
            displayNum = (tagList.length > 0) ? tagList.length + " tag" + (tagList.length > 1 ? "s" : "") : false;
            tagsToShow = displayNum ? [displayNum] : [];
        } else {
            const CHARACTER_LIMIT_PERCENTAGE = (viewWidth <= 375) ? 0.02 : 0.05;

            const characterLimit = Math.floor(viewWidth * CHARACTER_LIMIT_PERCENTAGE);



            let additionalTagsAdded = false;

            tagList.forEach(tag => {
                if (additionalTagsAdded) {
                    // Skip adding any more tags once "and X more" is added
                    return;
                }

                if (currentLength + tag.length <= characterLimit) {
                    tagsToShow.push(tag);
                    currentLength += tag.length;
                } else {
                    if (tagsToShow.length === 0) { // First tag itself is too long
                        const spaceForAndMore = " and X more".length;
                        const availableSpace = characterLimit - spaceForAndMore;
                        tagsToShow.push(`${tag.substring(0, availableSpace)}...`);
                        additionalTagsCount = tagList.length - 1;
                    } else {
                        additionalTagsCount = tagList.length - tagsToShow.length;
                    }
                    additionalTagsAdded = true; // Ensure no more tags are added
                }
            });
        }

        return (
            <>
                { tagsToShow.map((tagName) => (
                    <span key={ tagName } className="library-small-tag-text tag-text">{ tagName }</span>
                )) }
                { additionalTagsCount > 0 && (
                    <span className="library-small-tag-text tag-text">and { additionalTagsCount } more</span>
                ) }
            </>
        );
    };

    return (
        <div className="library-tag-manager tag-manager-wrapper">
            { showTags() }
            <span className="library-delete-book deleteHighlight" onClick={ () => handleBookDelete(bookID) }><DeleteIcon style={ { fontSize: "18px" } } /></span>

            <Popup trigger={ <span className=" library-tag-button tag-text add-tag"><LocalOfferIcon style={ { fontSize: "18px" } } /></span> } position="top">
                <div className="popup add-tag-popup">
                    <h2>Manage Tags</h2>
                    <input type="text" value={ tagInput } onChange={ (e) => setTagInput(e.target.value) } />
                    <button onClick={ handleTagAdd }>Add Tag</button>
                    <div className="current-tags-list-popup">
                        <p>
                            {
                                tagList.map((tag) => (
                                    <span key={ tag } className="current-tags-list-item">{ tag }[x]</span>
                                ))
                            }
                        </p></div>
                </div>
            </Popup>



        </div>
    );
};

export default LibraryTagManager;
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { auth, db, storage } from '../../components/shared/firebase';
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getDocs, query, where, collection, orderBy, limit, startAfter, Timestamp } from "firebase/firestore";
import { useNavigate, Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import RefreshIcon from '@mui/icons-material/Refresh';
import Book from './Book.js';
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Library.css";
import "../../assets/styles/EReader.css";

const Library = ({user}) => {
  const MAX_ITEMS_PER_PAGE = 50;
  const MAX_READ_BOOKS = 50;

  const { book_id } = useParams();
  const navigate = useNavigate();
  
  const [menuVisible, setMenuVisible] = useState(false);
  const [library, setLibrary] = useState([]);
  const [showUnread, setShowUnread] = useState(true);
  const [showRead, setShowRead] = useState(false);
  const [showAddDropdown, setShowAddDropdown] = useState(false);
  const [visualLibrary, setVisualLibrary] = useState([]);
  const [readBooks, setReadBooks] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);



  const menuRef = useRef(null);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [book_id]);

  const lastMarkedBookIdRef = useRef(null);
  useEffect(() => {
    const markBookAsRead = async () => {
      if (!user.uid || !book_id || lastMarkedBookIdRef.current === book_id) return;
  
      lastMarkedBookIdRef.current = book_id; // Update the ref to the current book_id
      const userReadBookRef = doc(db, "users", user.uid, "readBooks", book_id);
      try {
        await setDoc(userReadBookRef, { read: true, timestamp: Timestamp.now() });
      } catch (error) {
        console.error("Error marking book as read:", error);
      }
    };
  
    markBookAsRead();
  }, [book_id, user.uid]);

  useEffect(() => {
    const fetchReadBooks = async () => {
      if (!user.uid) return;
  
      const querySnapshot = await getDocs(collection(db, "users", user.uid, "readBooks"), limit(MAX_READ_BOOKS));
      const readBooksList = querySnapshot.docs.map(doc => doc.id); // Assuming you're just interested in the IDs
      setReadBooks(readBooksList);
      setContentLoaded(true);
    };
  
    fetchReadBooks();
  }, [user.uid]);


  useEffect(() => {
    const existingShowUnread = localStorage.getItem('showUnread');
    const existingShowRead = localStorage.getItem('showRead');

    if (existingShowUnread === "true") setShowUnread(true);
    if (existingShowUnread === "false") setShowUnread(false);

    if (existingShowRead === "true") setShowRead(true);
    if (existingShowRead === "false") setShowRead(false);

    if (existingShowUnread === null) localStorage.setItem('showUnread', showUnread);
    if (existingShowRead === null) localStorage.setItem('showRead', showRead);
  }, []);



  const loadLibrary = async () => {
    try {
      // Ensure the base query is set up correctly
      let baseQuery = query(collection(db, 'masterLibrary'));

      // if (showUnread) {
      //   const oneDayAgo = new Date();
      //   oneDayAgo.setHours(oneDayAgo.getHours() - 24);
      //   const timestampForQuery = Timestamp.fromDate(oneDayAgo);
      //   baseQuery = query(baseQuery, where('timestamp', '>=', timestampForQuery));
      // }


      baseQuery = query(baseQuery, orderBy('timestamp', 'desc'));
      baseQuery = query(baseQuery, limit(MAX_ITEMS_PER_PAGE));

      const querySnapshot = await getDocs(baseQuery);
      if (!querySnapshot.empty) {
        let items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setLibrary(items);
        setVisualLibrary(items);
      } else {
        // Handle the case where no documents are found
      }
    } catch (error) {
      console.error("Error fetching library data:", error);
    }
  };


  useEffect(() => {
    if (!user.uid) return;
    loadLibrary();
  }, [user.uid]);

  useEffect(() => {
    const refreshVisualLibrary = () => {
      let items = sortLibrary(library, 'New to Old');

      setVisualLibrary([...items]);
    }
    refreshVisualLibrary();
  }, [library]);

  

  const sortLibrary = (items, order) => {
    switch (order) {
      case 'New to Old':
        return items.sort((a, b) => b.timestamp - a.timestamp);
      case 'Old to New':
        return items.sort((a, b) => a.timestamp - b.timestamp);
      case 'A to Z':
        return items.sort((a, b) => a.title.localeCompare(b.title));
      case 'Z to A':
        return items.sort((a, b) => b.title.localeCompare(a.title));
      default:
        return items;
    }
  };

  const handleLogout = () => {
    setTimeout(() => {
      signOut(auth)
        .then(() => navigate("/"))
        .catch((error) => console.error("Logout error:", error));
    }, 100); // Delay logout by 100ms
  };

  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleDashboardClick = (event) => {
    // Check if the clicked area is not the menu and not one of its descendants
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }

    if (showAddDropdown) {
      setShowAddDropdown(false);
    }
  };

  const switchToTab = (tab) => {
    const unread = (tab === "unread");
    const read = (tab === "read");

    setShowRead(read);
    localStorage.setItem('showRead', read);
    setShowUnread(unread);
    localStorage.setItem('showUnread', unread);
  };

  const loadComponent = () => (
    <div className="dashboardContainer" onClick={ handleDashboardClick }>
      <div className="user-top-menu-bar"></div>
      <div className="userMenu">
        {/* User Menu */ }
        {
          <div className={ menuVisible ? "menuBarContainer activeMenu" : "menuBarContainer" } onClick={ handleMenuClick }>
            <div className="menuBar"></div>
            <div className="menuBar"></div>
            <div className="menuBar"></div>
          </div>
        }

        { menuVisible ? (
          <div ref={ menuRef } className="floatingMenuContainer">
            <div className={ `floatingMenuItem ${book_id ? "" : "active-page"}` } onClick={ () => { navigate("/"); setMenuVisible(false) } }>Library</div>
            <div className="floatingMenuItem" onClick={ () => { navigate("/highlights"); setMenuVisible(false) } }>Highlights</div>
            <div className="floatingMenuItem subscription" onClick={ () => { navigate("/account"); setMenuVisible(false) } }>Manage Subscription</div>
            <div className="floatingMenuItem" onClick={ () => { navigate("/help"); setMenuVisible(false) } }>Help</div>
            <div className="floatingMenuItem logout" onClick={ handleLogout }>Logout</div>
          </div>
        ) : "" }
      </div>
      <div className="libraryContainer" onClick={ handleDashboardClick }>
        { book_id && user.uid ? <div className="e-reader"><Book bookID={ book_id } userID={ user.uid } /></div> : (
          <div>
            <br /><br />
            <div className="importItems">
            </div>
            <div className="libraryNav">
              <div className="libraryControls">
                <div className="checkboxes">
                  <div className={ `unread-box ${showUnread ? "active-box" : "inactive-box"}` }>
                    <span className="checkbox-label" onClick={ () => switchToTab('unread') }>Unread</span>
                  </div>
                  <div className={ `read-box ${showRead ? "active-box" : "inactive-box"}` }>
                    <span
                      className="checkbox-label"
                      onClick={ () => switchToTab('read') }
                    >Read</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="libraryList">
              { visualLibrary.map((book, index) => (
                (showUnread && !readBooks.includes(book.id)) || (showRead && readBooks.includes(book.id)) ?
                  <React.Fragment key={ index }>
                    <div className="library-item">
                      <p key={ index } onClick={ () => navigate(`/${book.id}`) }><Link to={ `/${book.id}` }>{ book.title }</Link><br /><span className="date-desc">{ book.timestamp && book.timestamp.seconds ? new Date(book.timestamp.seconds * 1000).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }) : "Just Now" }</span>{/** <br /><span className="original-url-desc">{ extractBaseDomain(book.originalUrl) }</span> **/ }</p>
                    </div>
                  </React.Fragment> : ""
              )) }

            </div>

            <div className="loadOlder">

              {
                contentLoaded && visualLibrary
                  .filter((book, index) => (showUnread && !readBooks.includes(book.id)) || (showRead && readBooks.includes(book.id)))
                  .length === 0 ?
                  <><div className="caughtUpText">You're caught up with the latest news.</div>
                  <div className="refreshButton" onClick={()=>navigate(0)}><RefreshIcon className="libraryRefreshButton" /> Refresh</div>
                  { !showRead && <div className="loadMoreBtnWrapper"><button onClick={ () => { setShowRead(true); localStorage.setItem('showRead', true); setShowUnread(false); localStorage.setItem('showUnread', false); } } className="load-more-btn">Review Completed Articles</button></div> }
                 </>
                  :
                  ""
              }
              { /** showRead && <div className="loadMoreBtnWrapper loadMoreRead"><button onClick={ loadMore } className="load-more-btn">Load More</button></div> **/ }
            </div>
          </div>

        ) }

      </div>
      <div className="footer">
      </div>
    </div>
  );

  return (
    contentLoaded && loadComponent()
  )
}

export default Library;

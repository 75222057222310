// src/components/dashboard/Dashboard.js

import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../../components/shared/firebase';
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { getDocs, query, where, collection, addDoc, orderBy, setDoc, doc, deleteDoc, limit, Timestamp, startAfter } from "firebase/firestore";
import { useNavigate, Link, json } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from 'docx';
import AddIcon from '@mui/icons-material/Add';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import IosShareIcon from '@mui/icons-material/IosShare';
import LinkIcon from '@mui/icons-material/Link';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Library.css";
import "../../assets/styles/Highlights.css";

import { useParams } from 'react-router-dom';
import LibraryTagManager from './LibraryTagManager.js';

const mapBooks = {
  1: "/Anna Karenina.epub",
  2: "/Sun Also Rises.epub",
  3: "/To Kill a Mockingbird.epub",
  4: "/Dantes Inferno.epub",
  5: "/epubtohtml.epub",
  6: "/txt_to_epub.epub",
  7: "/The PreIPO Code - Webinar Script.epub",
  8: "/Jane Eyre.epub",
};

const MAX_ITEMS_PER_PAGE = 10;

const Highlights = () => {
  const { book_id } = useParams();
  const [user, setUser] = useState(null); // Replace with actual auth logic
  const [userID, setUserID] = useState(null);
  const [activeBook, setActiveBook] = useState(0); // Replace with logic to determine if the user has an active book
  const [activeBookName, setActiveBookName] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const [urlInput, setUrlInput] = useState("");
  const [library, setLibrary] = useState([]);
  const [visualLibrary, setVisualLibrary] = useState([]);
  const [loadingURL, setLoadingURL] = useState("");
  const [saveUnder, setSaveUnder] = useState("");
  const [uploadInput, setUploadInput] = useState("");
  const [lastDocument, setLastDocument] = useState(null);

  const [searchTags, setSearchTags] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [sortOrder, setSortOrder] = useState('New to Old');
  const [showUnread, setShowUnread] = useState(true);
  const [showRead, setShowRead] = useState(false);
  const [filterMode, setFilterMode] = useState('One or More');
  const [allTags, setAllTags] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [showAddDropdown, setShowAddDropdown] = useState(false);
  const [showImportURLModal, setShowImportURLModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showLoadFilterModal, setShowLoadFilterModal] = useState(false);
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);

  const navigate = useNavigate();

  const toggleAddDropdown = () => setShowAddDropdown(!showAddDropdown);

  const openImportURLModal = () => {
    setShowAddDropdown(false);
    setShowImportURLModal(true);
  };

  const openUploadFileModal = () => {
    setShowAddDropdown(false);
    setShowUploadFileModal(true);
  };

  const openLoadFilterModal = () => {
    setShowLoadFilterModal(true);
  };

  const openSaveFilterModal = () => {
    setShowSaveFilterModal(true);
  }


  const handleBlur = (event) => {
    setIsSearchFocused(false);
  };

  const getAllDBItems = async () => {
    // get all items in DB that match current search
    let result = [];
    try {
      let queryConstraints = [
        collection(db, 'highlights'),
        where('owner', '==', userID),
      ];

      // Handling "One or More" search mode with searchTags
      if (searchTags.length > 0 && filterMode === 'One or More') {
        queryConstraints.push(where('tags', 'array-contains-any', searchTags));
      }

      // Add ordering based on sortOrder state
      switch (sortOrder) {
        case 'New to Old':
          queryConstraints.push(orderBy('timestamp', 'desc'));
          break;
        case 'Old to New':
          queryConstraints.push(orderBy('timestamp', 'asc'));
          break;
        case 'A to Z':
          queryConstraints.push(orderBy('title', 'asc'));
          break;
        case 'Z to A':
          queryConstraints.push(orderBy('title', 'desc'));
          break;
        default:
          break;
      }
      const querySnapshot = await getDocs(query(...queryConstraints));

      let items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // "All" filtering mode will be handled client-side
      if (filterMode === 'All' && searchTags.length > 0) {
        items = items.filter(item =>
          searchTags.every(tag => item.tags.includes(tag))
        );
      }

      // If sorting by title, sort in the client side as Firestore doesn't support array-contains with orderBy
      if (['A to Z', 'Z to A'].includes(sortOrder)) {
        items = sortLibrary(items, sortOrder);
      }

      result = items;
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    return result;
  }

  const exportToDocx = async () => {
    const items = await getAllDBItems();

    let allChildren = [];

    if (searchTags.length > 0) {
      allChildren.push(new Paragraph({
        children: [new TextRun({ text: `Highlights Tagged: ${searchTags.join(', ')}`, bold: true, size: 24 })],
        spacing: { after: 240 },
        heading: HeadingLevel.HEADING_1,
      }));
    } else {
      allChildren.push(new Paragraph({
        children: [new TextRun({ text: "All Highlights", bold: true, size: 24 })],
        spacing: { after: 240 },
        heading: HeadingLevel.HEADING_1,
      }));
    }


    items.forEach((highlight, index) => {
      // Add highlight details
      allChildren.push(
        new Paragraph({
          children: [new TextRun({ text: `${highlight.content}`, bold: true, size: 20 })],
          spacing: { after: 120 },
        })
      );

      if (highlight.note !== "") {
        allChildren.push(new Paragraph({
          children: [new TextRun({ text: `${highlight.note}`, size: 16 })],
          spacing: { after: 120 },
        }));
      }

      allChildren.push(
        new Paragraph({
          children: [new TextRun({ text: `${highlight.sourceURL}`, size: 12 })],
          spacing: { after: 120 },
        }),
      );

      // Add a separator after each highlight except the last one
      if (index < items.length - 1) {
        allChildren.push(
          new Paragraph({
            text: '', // Empty text for a visual break
            border: {
              bottom: {
                color: "000000",
                space: 1,
                value: "single",
                size: 6,
              },
            },
            spacing: { after: 120 },
            thematicBreak: true,
          })
        );
      }
    });

    // Create a single document section with all paragraphs
    const doc = new Document({
      sections: [{
        properties: {},
        children: allChildren,
      }],
    });
    // Create a Blob from the toMarkdown string
    const blob = await Packer.toBlob(doc);

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = url;
    const fileName = searchTags.length > 0 ? `Markup_Highlights_Export_${searchTags.map(tag => (tag.replace(/ /g, '-'))).join('_')}` : "Markup_Highlights_Export_All";
    anchor.download = `${fileName}.docx`;

    anchor.download = `${fileName}.docx`;

    // Append the anchor to the body, trigger click, and then remove it
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up by revoking the blob URL and removing the anchor
    URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  };

  const exportToCSV = async (readwise = false) => {
    let csvContent = "";

    // Assuming getAllDBItems() fetches your items as before
    const items = await getAllDBItems();

    // Add CSV Header
    csvContent += "Highlight,Title,URL,Note,Tags\n";

    items.map(highlight => {
      // Prepare the data, ensuring to escape commas and quotes
      const content = `"${highlight.content.replace(/"/g, '""')}"`;
      const note = highlight.note !== "" ? `${highlight.note.replace(/"/g, '""')}` : '';
      const sourceURL = `"${highlight.sourceURL.replace(/"/g, '""')}"`;
      const sourceTitle = `"${highlight.sourceTitle.replace(/"/g, '""')}"`;
      const tags = `${highlight.tags.map(tag => "" + tag.replace(/ /g, '_')).join(' ')}`;

      // Append the item to csvContent
      csvContent += `${content},${sourceTitle},${sourceURL},"${note}","${tags}"\n`;

      return true;
    });

    // Create a Blob from the toMarkdown string
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = url;
    const fileName = searchTags.length > 0 ? `Markup_Highlights_Export_${searchTags.map(tag => (tag.replace(/ /g, '-'))).join('_')}` : "Markup_Highlights_Export_All";
    anchor.download = `${fileName}.csv`;

    anchor.download = `${fileName}.csv`;

    // Append the anchor to the body, trigger click, and then remove it
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up by revoking the blob URL and removing the anchor
    URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  };


  const exportToCSVForReadwise = async (readwise = false) => {
    let csvContent = "";

    // Assuming getAllDBItems() fetches your items as before
    const items = await getAllDBItems();

    // Add CSV Header
    csvContent += "Highlight,Title,URL,Note\n";

    items.map(highlight => {
      // Prepare the data, ensuring to escape commas and quotes
      const content = `"${highlight.content.replace(/"/g, '""')}"`;
      const note = highlight.note !== "" ? `${highlight.note.replace(/"/g, '""')}` : '';
      const sourceURL = `"${highlight.sourceURL.replace(/"/g, '""')}"`;
      const sourceTitle = `"${highlight.sourceTitle.replace(/"/g, '""')}"`;
      const tags = `${highlight.tags.map(tag => "." + tag.replace(/ /g, '_')).join(' ').toLowerCase()}`;

      // Append the item to csvContent
      csvContent += `${content},${sourceTitle},${sourceURL},"${tags + " " + note}"\n`;

      return true;
    });

    // Create a Blob from the toMarkdown string
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = url;
    const fileName = searchTags.length > 0 ? `Markup_Highlights_Export_${searchTags.map(tag => (tag.replace(/ /g, '-'))).join('_')}_Readwise` : "Markup_Highlights_Export_All_Readwise";
    anchor.download = `${fileName}.csv`;

    anchor.download = `${fileName}.csv`;

    // Append the anchor to the body, trigger click, and then remove it
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up by revoking the blob URL and removing the anchor
    URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  };

  const exportToMarkdown = async () => {
    let toMarkdown = "";

    const items = await getAllDBItems();

    items.map(highlight => {
      toMarkdown += `
***
> ${highlight.content}
${highlight.note !== "" ? `${"\n"}**NOTE:** ${highlight.note}` : ""}
${"```"}
${highlight.sourceURL}
${"```"}
[${highlight.sourceTitle}](https://social-reading.vercel.app/${highlight.bookID})

${highlight.tags.map(tag => "#" + tag.replace(/ /g, '_')).join(' ')}
`;

      return true;
    });

    // Create a Blob from the toMarkdown string
    const blob = new Blob([toMarkdown], { type: 'text/markdown;charset=utf-8' });

    // Create a URL for the blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = url;
    const fileName = searchTags.length > 0 ? `Markup_Highlights_Export_${searchTags.map(tag => (tag.replace(/ /g, '-'))).join('_')}` : "Markup_Highlights_Export_All";
    anchor.download = `${fileName}.md`;

    anchor.download = `${fileName}.md`;

    // Append the anchor to the body, trigger click, and then remove it
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up by revoking the blob URL and removing the anchor
    URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  };

  const menuRef = useRef(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in
        setUser(firebaseUser);
        setUserID(firebaseUser.uid);
      } else {
        // User is signed out
        setUser(null);
        setUserID(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  const loadLibrary = async () => {
    try {
      let queryConstraints = [
        collection(db, 'highlights'),
        where('owner', '==', userID),
        limit(MAX_ITEMS_PER_PAGE)
      ];

      // Handling "One or More" search mode with searchTags
      if (searchTags.length > 0) {
        queryConstraints.push(where('tags', 'array-contains-any', searchTags));
      }

      // Add ordering based on sortOrder state
      switch (sortOrder) {
        case 'New to Old':
          queryConstraints.push(orderBy('timestamp', 'desc'));
          break;
        case 'Old to New':
          queryConstraints.push(orderBy('timestamp', 'asc'));
          break;
        case 'A to Z':
          queryConstraints.push(orderBy('title', 'asc'));
          break;
        case 'Z to A':
          queryConstraints.push(orderBy('title', 'desc'));
          break;
        default:
          break;
      }
      const querySnapshot = await getDocs(query(...queryConstraints));

      let items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // "All" filtering mode will be handled client-side
      if (filterMode === 'All' && searchTags.length > 0) {
        items = items.filter(item =>
          searchTags.every(tag => item.tags.includes(tag))
        );
      }

      // If sorting by title, sort in the client side as Firestore doesn't support array-contains with orderBy
      if (['A to Z', 'Z to A'].includes(sortOrder)) {
        items = sortLibrary(items, sortOrder);
      }

      setLibrary(items);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const loadMore = async () => {
    try {
      const lastItem = library[library.length - 1];
      // Assuming 'timestamp' is the field used for ordering in your Firestore collection
      const lastTimestamp = lastItem ? new Timestamp(lastItem.timestamp.seconds, lastItem.timestamp.nanoseconds) : null;

      const orderDirection = sortOrder === 'Old to New' ? 'asc' : 'desc';
      const orderConstraint = orderBy('timestamp', orderDirection);

      let extraFilters = [];
      if (searchTags.length > 0) {
        extraFilters.push(where("tags", "array-contains-any", searchTags));
      }

      let queryConstraints = [
        collection(db, 'highlights'),
        where('owner', '==', userID),
        ...extraFilters,
        orderConstraint
      ];

      if (lastItem) {
        queryConstraints.push(startAfter(lastItem.timestamp));
      }

      queryConstraints.push(limit(MAX_ITEMS_PER_PAGE));

      const newQuery = query(...queryConstraints);
      const querySnapshot = await getDocs(newQuery);

      if (!querySnapshot.empty) {
        let fetchedItems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Ensure items are not already in the library
        fetchedItems = fetchedItems.filter(item => !library.some(libItem => libItem.id === item.id));

        // If the "ALL" search filter is enabled, further filter the items
        if (filterMode === 'All' && searchTags.length > 0) {
          fetchedItems = fetchedItems.filter(item =>
            searchTags.every(tag => item.tags && item.tags.includes(tag))
          );
        }

        // Update the library
        setLibrary(prevLibrary => [...prevLibrary, ...fetchedItems]);
        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    } catch (error) {
      console.error("Error fetching more items:", error);
    }
  };

  useEffect(() => {
    if (!userID) return;
    loadLibrary();
  }, [userID, searchTags, sortOrder, filterMode]);

  const addTagToSearch = (tag) => {
    const tagToAdd = tag || searchInput.trim().toUpperCase();
    if (tagToAdd && !searchTags.includes(tagToAdd)) {
      setSearchTags(prevTags => {
        const updatedTags = [...prevTags, tagToAdd];
        return updatedTags;
      });
      setSearchInput('');
    }
  };

  const removeTagFromSearch = (tag) => {
    setSearchTags(prevTags => {
      const updatedTags = prevTags.filter(t => t !== tag);
      return updatedTags;
    });
  };

  const removeAllTagsFromSearch = () => {
    setSearchTags(prevTags => {
      const updatedTags = [];
      return updatedTags;
    });
  };

  const sortLibrary = (items, order) => {
    switch (order) {
      case 'New to Old':
        return items.sort((a, b) => b.timestamp - a.timestamp);
      case 'Old to New':
        return items.sort((a, b) => a.timestamp - b.timestamp);
      case 'A to Z':
        return items.sort((a, b) => a.title.localeCompare(b.title));
      case 'Z to A':
        return items.sort((a, b) => b.title.localeCompare(a.title));
      default:
        return items;
    }
  };

  const filterLibrary = (items, tags, mode) => {
    return items.filter(item => {
      let tagsCondition = true;
      if (tags.length > 0) {
        if (mode === 'One or More') {
          tagsCondition = item.tags && tags.some(tag => item.tags.includes(tag));
        } else if (mode === 'All') {
          tagsCondition = item.tags && tags.every(tag => item.tags.includes(tag));
        }
      }
      return tagsCondition;
    });
  };



  const handleLogout = () => {
    setTimeout(() => {
      signOut(auth)
        .then(() => navigate("/"))
        .catch((error) => console.error("Logout error:", error));
    }, 100); // Delay logout by 100ms
  };


  // Handler to set the active book after a choice is made
  const handleBookSelect = (bookId) => {
    // TODO: Update the user's active book in the state and possibly in the database
    setActiveBook(bookId);
    setActiveBookName(mapBooks[bookId]);
  };

  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleUrlSubmit = async () => {
    if (urlInput !== "") {
      const storedInput = urlInput;
      setUrlInput("");
      setLoadingURL(storedInput);
      try {
        const response = await fetch('http://localhost:3001/fetch-article/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ url: storedInput })
        });
        const data = await response.json();
        const jsonData = data;
        const title = jsonData[0].content;

        // Generate a unique filename
        const uniqueFilename = `${userID}_${new Date().getTime()}.json`;

        // Create a reference to the storage service
        const fileRef = ref(storage, uniqueFilename);

        try {
          // Upload JSON data to Firebase Storage
          await uploadString(fileRef, JSON.stringify(jsonData), 'raw', { contentType: 'application/json' });
          const fileUrl = await getDownloadURL(fileRef);

          // Save metadata in Firestore
          const newBookRef = await addDoc(collection(db, 'userLibrary'), {
            owner: userID,
            originalUrl: storedInput,
            jsonFile: fileUrl,
            title: title,
            unread: true,
            archived: false,
            tags: [],
            timestamp: new Date() // Firestore timestamp
          });

          const newBook = {
            id: newBookRef.id,
            owner: userID,
            originalUrl: storedInput,
            jsonFile: fileUrl,
            title: title,
            unread: true,
            archived: false,
            tags: [],
            timestamp: new Date() // Use the same timestamp as in the Firestore document
          };

          // Update the library view here
          setLibrary([newBook, ...library]);
        } catch (error) {
          console.error('Error: ', error);
        }
      } catch (error) {
        console.error('Error: ', error);
      }

      setUrlInput("");
      setLoadingURL("");
      setSaveUnder("");
    }
  };

  const handleUploadSubmit = async () => {
    if (uploadInput !== "") {
      // handle the upload
      setUploadInput("");
    }
  };

  const handleDashboardClick = (event) => {
    // Check if the clicked area is not the menu and not one of its descendants
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }

    if (showAddDropdown) {
      setShowAddDropdown(false);
    }
  };

  const extractBaseDomain = (url) => {
    const domain = (new URL(url)).hostname;
    const parts = domain.split('.').reverse();
    if (parts.length >= 2) {
      return parts[1] + '.' + parts[0];
    }
    return domain;
  };

  const handleBookDelete = async (bookID) => {
    try {
      // Reference to the book document in the 'userLibrary' collection
      const bookRef = doc(db, "userLibrary", bookID);

      // Delete the book document
      await deleteDoc(bookRef);

      // Update the library state to remove the deleted book
      setLibrary(currentLibrary => currentLibrary.filter(book => book.id !== bookID));
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };

  const autocompleteSuggestions = searchInput ? allTags
    .filter(tag => tag.includes(searchInput.toUpperCase()))
    .slice(0, 3) // Limit to the top 3 results
    : [];

  const handleSuggestionMouseDown = (tag, event) => {
    event.preventDefault(); // Prevents the input from losing focus
    addTagToSearch(tag.trim().toUpperCase());
  };

  const closeImportURLModal = () => setShowImportURLModal(false);
  const closeUploadFileModal = () => setShowUploadFileModal(false);
  const closeLoadFilterModal = () => setShowLoadFilterModal(false);
  const closeSaveFilterModal = () => setShowSaveFilterModal(false);

  const ImportURLModal = () => (
    <div className="modal">
      <button onClick={ closeImportURLModal }>X</button>
      <input type="text" placeholder="Enter URL" value={ urlInput } onChange={ (e) => setUrlInput(e.target.value) } />
      <button onClick={ handleUrlSubmit }>Import</button>
    </div>
  );

  const UploadFileModal = () => (
    <div className="modal">
      <button onClick={ closeUploadFileModal }>X</button>
      <input type="file" onChange={ (e) => setUploadInput(e.target.files[0]) } />
      <button onClick={ handleUploadSubmit }>Upload</button>
    </div>
  );

  const LoadFilterModal = () => (
    <div className="modal">
      <button onClick={ closeLoadFilterModal }>X</button>
      EXPORT OPTIONS
    </div>
  );

  const SaveFilterModal = () => (
    <div className="modal">
      <button onClick={ closeSaveFilterModal }>X</button>
      [ Name This Filter ]

      [ ] Add to Favorites?

      [SAVE NEW FILTER]
    </div>
  );

  return (
    <div className="dashboardContainer highlightContainer" onClick={ handleDashboardClick }>
      <div className="user-top-menu-bar"></div>
      <div className="userMenu">
        {/* User Menu */ }
        {
          <div className={ menuVisible ? "menuBarContainer activeMenu" : "menuBarContainer" } onClick={ handleMenuClick }>
            <div className="menuBar"></div>
            <div className="menuBar"></div>
            <div className="menuBar"></div>
          </div>
        }

        { menuVisible ? (
          <div ref={ menuRef } className="floatingMenuContainer">
            <div className="floatingMenuItem" onClick={ () => { navigate("/"); setMenuVisible(false) } }>Library</div>
            <div className="floatingMenuItem active-page" onClick={ () => { navigate("/highlights"); setMenuVisible(false) } }>Highlights</div>
            <div className="floatingMenuItem subscription" onClick={ () => { navigate("/account"); setMenuVisible(false) } }>Manage Subscription</div>
            <div className="floatingMenuItem" onClick={ () => { navigate("/help"); setMenuVisible(false) } }>Help</div>
            <div className="floatingMenuItem logout" onClick={ handleLogout }>Logout</div>
          </div>
        ) : "" }
      </div>
      <div className="libraryContainer" onClick={ handleDashboardClick }>
        <div>
          <br /><br />
          <div className="importItems">
            {/* Import Items */ }
            <div className="importItems">


              <button className={ `${showAddDropdown ? "add-library-active" : ""} add-to-library-button` } onClick={ toggleAddDropdown }><IosShareIcon style={ { fontSize: "12px" } } />&nbsp;Export</button>
              { showAddDropdown && (
                <div className="addDropdown">
                  <button onClick={ () => { exportToDocx(); toggleAddDropdown(); } }>Word or Google Docs (DOCX)</button>
                  <button onClick={ () => { exportToCSV(); toggleAddDropdown(); } }>Excel or Google Sheets (CSV)</button>
                  <button onClick={ () => { exportToCSVForReadwise(); toggleAddDropdown(); } }>Readwise File (CSV)</button>
                  <button onClick={ () => { exportToMarkdown(); toggleAddDropdown(); } }>Markdown</button>
                </div>
              ) }
            </div>



            {/** <div className="addURL">
                  <input type="text" placeholder="URL" value={urlInput} onChange={(e) => setUrlInput(e.target.value)} />
                  <button onClick={handleUrlSubmit}>Import Web Page</button>
                </div>
                <div className="addFile">
                  <input type="text" placeholder="Upload" value={uploadInput} onChange={(e) => setUploadInput(e.target.value)} />
                  <button onClick={handleUploadSubmit}>Upload File</button>
                </div> **/}
            { loadingURL !== "" ? <div className="loadingURL">{ `Loading ${loadingURL}...` }</div> : "" }
          </div>
          <div className="libraryNav">
            {/* Library Navigation */ }
            <div className="filterContainer">
              <div className="tag-searchbox">
                <LocalOfferIcon style={ { position: "absolute", color: "#ccc", marginTop: "4px", marginLeft: "4px" } } />
                <input
                  className={ `${(autocompleteSuggestions.length > 0) ? "autoCompleteActive" : ""}` }
                  type="text"
                  placeholder="Filter by tag"
                  value={ searchInput }
                  onChange={ (e) => setSearchInput(e.target.value) }
                  onKeyDown={ (e) => e.key === 'Enter' && addTagToSearch() }
                  onFocus={ () => setIsSearchFocused(true) }
                  onBlur={ handleBlur }
                  style={ { textIndent: "25px" } }
                />
                { isSearchFocused && (autocompleteSuggestions.length > 0) && (
                  <div className="tag-suggestions">
                    <span className="suggestions-label">Suggestions</span>
                    { autocompleteSuggestions.map((tag, index) => (
                      <div className="individual-suggestion" key={ index } onMouseDown={ (e) => handleSuggestionMouseDown(tag, e) }>
                        { tag }
                      </div>
                    )) }
                  </div>
                ) }
              </div>

              <div className="tag-filter-list">
                <span className="currently-viewing-label">Viewing { searchTags.length === 0 ? <strong>All Highlights</strong> : <><strong>Highlights Tagged As</strong> { searchTags.length > 1 ? <><select className="filter-mode" value={ filterMode } onChange={ (e) => setFilterMode(e.target.value) }>
                  { searchTags.length > 2 ? <option value="One or More">Any</option> : <option value="One or More">Either</option> }
                  { searchTags.length > 2 ? <option value="All">All</option> : <option value="All">Both</option> }
                </select> { searchTags.length > 2 ? "Of" : "" }</> : "" } </> }</span>
                { searchTags.map((tag, index) => (
                  <span key={ index }>
                    { tag } <button onClick={ () => removeTagFromSearch(tag) }>X</button>
                  </span>
                )) }
                { searchTags.length > 1 ? <button onClick={ removeAllTagsFromSearch }>Clear All</button> : "" }
              </div>
            </div>
            <hr />
            <div className="libraryControls">
              {/* Library Controls */ }
              <div className="checkboxes">

              </div>
              { /**
              <div className="sortby">
                Sort By&nbsp;
                <select value={ sortOrder } onChange={ (e) => setSortOrder(e.target.value) }>
                  <option value="New to Old">New to Old</option>
                  <option value="Old to New">Old to New</option>
                </select>
              </div>
              **/ }
            </div>
          </div>

          <div className="libraryList">
            {/* Library List */ }
            { library.map((highlight, index) => (
              <React.Fragment key={ index }>
                <div className="library-item">
                  <p key={ index }><div className="highlightDecorate">{ highlight.content }</div>
                    { highlight.note !== "" ? <div className="highlightNoteContainer">
                      <br />
                      <span className="highlightNote">{ highlight.note }</span></div> : "" }<hr className="highlight-divider" />
                    <div className="highlightMetadata">
                      <div className="highlightOriginContainer"><span className="highlightOrigin-label"><ArrowOutwardIcon style={ { fontSize: "1.5em", position: "relative", top: "-2px", marginRight: "2px" } } /></span>
                        <span className="highlightOrigin"><a rel="noreferrer" target="_blank" href={ `/${highlight.bookID}` }>{ highlight.sourceTitle }</a></span>
                        { highlight.sourceURL !== "" ? <div className="highlightSourceContainer"><span className="highlightSource-label"><LinkIcon style={ { fontSize: "1.5em", position: "relative", top: "-2px", marginRight: "2px" } } /></span><span className="highlightSource">{ highlight.sourceURL }</span></div> : "" }
                        { highlight.tags.length > 0 ? <div className="highlightTagsContainer"><span className="highlightTags-label"><LocalOfferIcon style={ { fontSize: "1.5em", position: "relative", top: "-1px", marginRight: "2px" } } /></span><span className="highlightTags">{ highlight.tags.join(", ") }</span></div> : "" }
                      </div>
                    </div>
                  </p>
                </div>
                <div className="library-tag-manager">

                </div>
              </React.Fragment>
            )) }
          </div>
          <div style={ { marginTop: "150px" } }>
            <button onClick={ loadMore } className="load-more-btn">Load More</button>
          </div>
        </div>
      </div>
      <div className="footer">
      </div>
      { showImportURLModal && <ImportURLModal /> }
      { showUploadFileModal && <UploadFileModal /> }
      { showLoadFilterModal && <LoadFilterModal /> }
      { showSaveFilterModal && <SaveFilterModal /> }
    </div>
  );
}

export default Highlights;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../shared/firebase';
import { ref, uploadString } from 'firebase/storage';
import { doc, getDoc, collection, addDoc, query, where, getDocs, deleteDoc, setDoc } from 'firebase/firestore';
import "../../assets/styles/Book.css";
import '../../assets/styles/EReader.css'
import TagManager from "./TagManager";
import { act } from 'react-dom/test-utils';

export const Book = ({ userID, bookID }) => {
    const navigate = useNavigate();
    const [activeParagraphs, setActiveParagraphs] = useState(new Map());
    const [book, setBook] = useState(null);
    const [bookTitle, setBookTitle] = useState("");
    const [bookSourceURL, setBookSourceURL] = useState("");
    const [loadingHighlights, setLoadingHighlights] = useState(true);
    const [readyParagraph, setReadyParagraph] = useState(null);
    const [autoTag, setAutoTag] = useState("");
    const [autoTagHelpVisible, setAutoTagHelpVisible] = useState(false);
    
    // Load autoTag from localStorage on component mount
    useEffect(() => {
        const savedAutoTag = localStorage.getItem('autoTag');
        if (savedAutoTag) {
            setAutoTag(savedAutoTag);
        }
    }, []);
    
    // Save autoTag to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('autoTag', autoTag);
    }, [autoTag]);

    useEffect(() => {
        const loadBook = async () => {
            try {
                const bookRef = doc(db, 'masterLibrary', bookID);
                const bookDoc = await getDoc(bookRef);
    
                if (!bookDoc.exists()) {
                    throw new Error('Book not found');
                }
    
                const bookData = bookDoc.data();
                // if (bookData.owner !== userID) {
                //     throw new Error('Unauthorized access');
                // }
    
                // if (bookData.unread) {
                //     await updateDoc(bookRef, { unread: false });
                // }
    
                setBookTitle(bookData.title);
                setBookSourceURL(bookData.originalUrl);
                const response = await fetch(bookData.jsonFile);
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const jsonData = await response.json();
                setBook(jsonData);
            } catch (error) {
                console.error("Failed to load book:", error);
            }
        };
    
        loadBook();
    }, [bookID, userID]);
    

    useEffect(() => {
        if (!userID) return
        if (!bookID) return
        const fetchHighlightedParagraphs = async () => {
            const highlightsRef = collection(db, "highlights");
            const q = query(highlightsRef,
                            where("owner", "==", userID),
                            where("bookID", "==", bookID));
    
            try {
                const querySnapshot = await getDocs(q);
                const newActiveParagraphs = new Map();
                querySnapshot.forEach((doc) => {
                    const paragraphID = doc.data().paragraphID;
                    newActiveParagraphs.set(paragraphID, true);
                });
                setActiveParagraphs(newActiveParagraphs);
            } catch (error) {
                console.error("Error fetching highlighted paragraphs: ", error);
            }
        };
    
        fetchHighlightedParagraphs();
        setLoadingHighlights(false);
    }, [userID, bookID]);

    const handleParagraphClick = async (e) => {
        e.stopPropagation();
        const paragraphElement = e.target;
        const paragraphId = paragraphElement.getAttribute('data-paragraph-id');

        // If it's already active, do nothing
        if (activeParagraphs.get(paragraphId)) return;

        if (readyParagraph === paragraphId) {

            // First, show the highlight visually
            const paragraphText = paragraphElement.textContent || paragraphElement.innerText;
            paragraphElement.classList.remove('ready');
            setActiveParagraphs(prevActiveParagraphs => {
                const newActiveParagraphs = new Map(prevActiveParagraphs);
                const isActive = newActiveParagraphs.get(paragraphId);
        
                if (!isActive) {
                    paragraphElement.classList.add('active');
                }
        
                newActiveParagraphs.set(paragraphId, !isActive);
                return newActiveParagraphs;
            });

            // Add the highlight to the DB, including any auto tags
            await handleHighlightAdd(paragraphId, paragraphText);

            // Now update one more time to reflect auto tags if we added any
            setActiveParagraphs(prevActiveParagraphs => {
                const newActiveParagraphs = new Map(prevActiveParagraphs);
                const isActive = newActiveParagraphs.get(paragraphId);
                newActiveParagraphs.set(paragraphId, isActive);
                return newActiveParagraphs;
            });

            setReadyParagraph(null);
        } else {
            document.querySelector(`[data-paragraph-id="${readyParagraph}"]`)?.classList.remove('ready');
            setReadyParagraph(paragraphId);
            paragraphElement.classList.add('ready');
        }

    };

    const handleHighlightDelete = async (paragraphID) => {
        document.querySelector(`[data-paragraph-id="${paragraphID}"]`)?.classList.remove('active');
        setActiveParagraphs(prevActiveParagraphs => {
            const newActiveParagraphs = new Map(prevActiveParagraphs);
            newActiveParagraphs.set(paragraphID.toString(), false);
            return newActiveParagraphs;
        });
        try {
            const highlightsRef = collection(db, "highlights");
            const q = query(highlightsRef, 
                            where("owner", "==", userID), 
                            where("bookID", "==", bookID), 
                            where("paragraphID", "==", paragraphID.toString()));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {                
                querySnapshot.forEach(async (doc) => {
                    await deleteDoc(doc.ref);
                });
            }
        } catch (error) {
            console.error("Error removing highlight from Firestore: ", error);
        }
    };


    const handleHighlightAdd = async (paragraphID, paragraphText) => {
        try {
            // Make sure it doesn't already exist
            const highlightsRef = collection(db, "highlights");
            const q = query(highlightsRef, 
                            where("owner", "==", userID), 
                            where("bookID", "==", bookID), 
                            where("paragraphID", "==", paragraphID));
            const querySnapshot = await getDocs(q);
            const tagsToAdd = (autoTag !== "") ? autoTag.split(',').map(tag => tag.trim().toUpperCase()) : [];

            if (querySnapshot.empty) {                
                const newHighlightData = {
                    owner: userID,
                    bookID: bookID,
                    paragraphID: paragraphID,
                    content: paragraphText,
                    tags: tagsToAdd,
                    note: "",
                    sourceTitle: bookTitle,
                    sourceURL: bookSourceURL,
                    timestamp: new Date(),
                };
            
                const docRef = await addDoc(collection(db, "highlights"), newHighlightData);
            }
        } catch (error) {
            console.error("Error adding highlight to Firestore: ", error);
        }
    };


    const CopyToClipboardButton = ({text}) => { 
        const [justCopied, setJustCopied] = useState(false);

        useEffect(() => {
            if (justCopied) {
                setTimeout(() => setJustCopied(false), 1500);
            }
        }, [justCopied]);

        const copyToClipboard = (text) => {
            navigator.clipboard.writeText(text);
            setJustCopied(true);
        }

        return (
            justCopied ? <span className="justcopied"></span> : <span className="copytoclipboard" onClick={() => copyToClipboard(text)}></span>
        );

    };

    const handleFrameClick = (e) => {
        if (e.target.getAttribute('data-paragraph-id') !== readyParagraph) {
            document.querySelector(`[data-paragraph-id="${readyParagraph}"]`)?.classList.remove('ready');
            setReadyParagraph(null);
        }
    }
    

    return (
        <div onClick={handleFrameClick}>
            { book && !loadingHighlights && book.map((item, index) => {
                const isActive = activeParagraphs.get(item.id.toString());
                const activeClass = isActive ? 'active' : '';

                if (item.content === "Data is delayed at least 15 minutes.") return null;
                
                switch (item.tag) {
                    case 'summary':
                        return (
                            <div className="paragraph-container" key={index}>
                                <CopyToClipboardButton text={item.content} />
                                <span className="summary-background">Background</span>
                                <p className={`summary ${activeClass}`}>{item.content}</p>
                                {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id} handleHighlightDelete={handleHighlightDelete} highlightedText={item.content} /> : "" }
                            </div>
                        );
                    case 'p':
                        return (
                            <div className="paragraph-container" key={index}>
                                <CopyToClipboardButton text={item.content} />
                                <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={activeClass}>{item.content}</p>
                                {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id} handleHighlightDelete={handleHighlightDelete} highlightedText={item.content} /> : "" }

                            </div>
                        );
                    case 'h1':
                            return (
                                <div className="paragraph-container" key={index}>
                                    <CopyToClipboardButton text={item.content} />
                                    <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={activeClass}><span style={{fontWeight: 700}}>{item.content}</span></p>
                                    {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id} handleHighlightDelete={handleHighlightDelete} highlightedText={item.content} /> : "" }
    
                                </div>
                            );
                            case 'h2':
                                return (
                                    <div className="paragraph-container" key={index}>
                                        <CopyToClipboardButton text={item.content} />
                                        <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={activeClass} style={{fontWeight: 700, fontSize: "1.4em"}}>{item.content}</p>
                                        {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id}   handleHighlightDelete={handleHighlightDelete} highlightedText={item.content} /> : "" }
        
                                    </div>
                                );
                                case 'h3':
                                    return (
                                        <div className="paragraph-container" key={index}>
                                            <CopyToClipboardButton text={item.content} />
                                            <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={activeClass} style={{fontWeight: 700, fontSize: "1em"}}>{item.content}</p>
                                            {isActive ? <TagManager autoTag={autoTag} userID={userID} bookID={bookID} paragraphID={item.id} handleHighlightDelete={handleHighlightDelete} highlightedText={item.content} /> : "" }
            
                                        </div>
                                    );
                                    case 'h4':
                                        return (
                                            <div className="paragraph-container" key={index}>
                                                <CopyToClipboardButton text={item.content} />
                                                <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={activeClass} style={{fontWeight: 700, fontSize: ".9em"}}>{item.content}</p>
                                                {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id} highlightedText={item.content} handleHighlightDelete={handleHighlightDelete} /> : "" }
                
                                            </div>
                                        );
                                        case 'h5':
                                            return (
                                                <div className="paragraph-container" key={index}>
                                                    <CopyToClipboardButton text={item.content} />
                                                    <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={activeClass} style={{fontWeight: 700, fontSize: ".8em"}}>{item.content}</p>
                                                    {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id} handleHighlightDelete={handleHighlightDelete} highlightedText={item.content} /> : "" }
                    
                                                </div>
                                            );
                                            case 'h6':
                                                return (
                                                    <div className="paragraph-container" key={index}>
                                                        <CopyToClipboardButton text={item.content} />
                                                        <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={activeClass} style={{fontWeight: 700, fontSize: ".7em"}}>{item.content}</p>
                                                        {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id} highlightedText={item.content} handleHighlightDelete={handleHighlightDelete} /> : "" }
                        
                                                    </div>
                                                );
                                                case 'li':
                                                    return (
                                                        <div className="paragraph-container" key={index}>
                                                            <CopyToClipboardButton text={item.content} />
                                                            <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={`listItem ${activeClass}`} style={{marginLeft: "40px", fontSize: ".95em"}}>{item.content}</p>
                                                            {isActive ? <TagManager userID={userID} bookID={bookID} paragraphID={item.id} highlightedText={item.content} handleHighlightDelete={handleHighlightDelete} /> : "" }
                            
                                                        </div>
                                                    );
                    case 'title':
                        return (<>
                            {/** <div className="autoTag">
                                <span className="autoTagLabel">Auto Tag</span> <input type="text" value={autoTag} onChange={(e) => setAutoTag(e.target.value)} />
                                <span 
                                    className="autoTagInfo" 
                                    onMouseEnter={() => setAutoTagHelpVisible(true)} 
                                    onMouseLeave={() => setAutoTagHelpVisible(false)}
                                >
                                    <span className="howToUseTooltip" style={{fontSize: ".5em", borderBottom: "1px dotted", marginLeft: "5px", cursor:"pointer"}}>How to Use</span> 
                                   {autoTagHelpVisible &&  <span className="howToUseInstructions" style={{fontSize: ".5em", "border-bottom":"1px solid dotted", marginLeft:"5px", position:"fixed"}}>Tags in this box are auto-applied to every new highlight on this page. Separate multiple tags with a comma.</span> }
                                </span>
                        </div> **/ }
                            { bookSourceURL !== "" ?
                            <div className="sourceUrl">
                                <a target="_blank" rel="noreferrer" href={`${bookSourceURL}`}>Source</a>
                                <span className="urlText"></span>
                            </div>
                            : "" }
                                    <div className="paragraph-container" key={index}>
                                    <p onClick={handleParagraphClick} data-paragraph-id={item.id} className={`articleTitle ${activeClass}`}>
                                        { item.content }
                                    </p>
                                    {isActive ? <TagManager userID={userID} bookID={bookID}  autoTag={autoTag} paragraphID={item.id} handleHighlightDelete={handleHighlightDelete} highlightedText={item.content} /> : "" }
                                    <hr style={{cursor: "default", marginTop:`${isActive ? "150px" : "50px"}`, marginBottom: "-50px"}}/>
                                    </div>

                            </>);
                    default:
                        return null;
                }
            }) }
            { book && !loadingHighlights &&
            <div className="backToLibrary">
                <button style={{fontFamily:"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, sans-serif"}} onClick={()=>navigate("/")}>Back to Library</button>
            </div> }
        </div>
    );
};

export default Book;



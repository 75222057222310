import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

const MuiList = ({ textAlign = '', listType = '', children, ...props }) => {
  
  return (
    <Box sx={{ 
        ml: 3, 
      width: '75%',
      margin: 'auto',
      display: 'block',
    }} {...props}> {/* Adjust the margin as needed */}
      <Typography component="div">
        <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}> 
         {children}
        </ul>
      </Typography>
    </Box>
  );
};

const MuiListItem = ({ children, ...props }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const bgColor = prefersDarkMode ? '#222222': '#efefef';
  return (
    <li>
      <Typography  sx={{backgroundColor: bgColor, padding: '15px', marginTop: '10px', fontSize: '1.2em'}} {...props}>
            {children}
        </Typography>
    </li>
  );
};

export { MuiList, MuiListItem };

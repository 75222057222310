import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { db } from '../shared/firebase';
import { doc, setDoc, query, collection, where, getDocs, updateDoc, arrayUnion, arrayRemove, orderBy, limit } from 'firebase/firestore';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreateIcon from '@mui/icons-material/Create';
import 'reactjs-popup/dist/index.css';
import "../../assets/styles/TagManager.css";

const TagManager = ({ userID, bookID, paragraphID, highlightedText, handleHighlightDelete }) => {
    const [tagList, setTagList] = useState([]);
    const [deleteHighlightConfirm, setDeleteHighlightConfirm] = useState(false);
    const [tagInput, setTagInput] = useState("");
    const [noteInput, setNoteInput] = useState("");

    const [openManageTagModal, setOpenManageTagModal] = useState(false);

    const handleOpenManageTagModal = () => {
        setOpenManageTagModal(true);
    };

    const handleCloseManageTagModal = () => {
        setOpenManageTagModal(false);
    };


    useEffect(() => {
        const loadTags = async () => {
            const highlightsCollectionRef = collection(db, "highlights");
            const q = query(highlightsCollectionRef,
                where("owner", "==", userID),
                where("paragraphID", "==", paragraphID.toString()),
                where("bookID", "==", bookID)
            );
    
            try {
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    console.log("No matching highlights found");
                    return;
                }
    
                let tags = [];
                let noteText = "";
                let needToUpdate = false;
    
                querySnapshot.forEach(doc => {
                    const data = doc.data();
                    if (data.tags && data.tags.length > 0) {
                        tags = [...data.tags];
                    }
                    noteText = data.note;
                });
    
                // Removing duplicates if any
                tags = [...new Set(tags)];
                setTagList([...tags]);
                setNoteInput(noteText);
            } catch (error) {
                console.error("Error loading and updating tags: ", error);
            }
        };
    
        loadTags();
    }, []);
    

    const saveNote = async () => {
        // Update highlights collection
        const highlightsCollection = collection(db, "highlights");
        console.log("Query called in saveNote()");
        const q = query(highlightsCollection, where("owner", "==", userID), where("bookID", "==", bookID), where("paragraphID", "==", paragraphID.toString()));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) console.log("No matching highlights found");
        querySnapshot.forEach(async (doc) => {
            await updateDoc(doc.ref, {
                note: noteInput.trim(),
            });
        });
    };
    

    const handleTagAdd = async (e, customTagName="") => {
        const tagName = (customTagName === "") ? tagInput.trim().toUpperCase() : customTagName.trim().toUpperCase();
        if (tagList.includes(tagName)) return;

        if (tagName !== "") {
            // Update highlights collection
            const highlightsCollection = collection(db, "highlights");
            console.log("Query called in handleTagAdd()");
            const q = query(highlightsCollection, where("owner", "==", userID), where("paragraphID", "==", paragraphID.toString()), where("bookID", "==", bookID));

            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) console.log("No matching highlights found");
            querySnapshot.forEach(async (doc) => {
                await updateDoc(doc.ref, {
                    tags: arrayUnion(tagName)
                });
            });

            // Check if the tag already exists in the tags collection
            const tagsCollection = collection(db, "tags");
            console.log("Query called again in handleTagAdd() to see if record exists in tags")
            const tagQuery = query(tagsCollection, where("name", "==", tagName), where("owner", "==", userID));
            const tagQuerySnapshot = await getDocs(tagQuery);

            // If the tag doesn't exist, add it to the tags collection
            if (tagQuerySnapshot.empty) {
                const newTagRef = doc(tagsCollection);
                await setDoc(newTagRef, {
                    name: tagName,
                    owner: userID
                });
            }

            setTagList(prev => [...prev, tagName]);
            setTagInput("");
        }
    };

    const removeTag = async (tagToRemove) => {
        // Update the Firestore document
        const highlightsCollection = collection(db, "highlights");
        console.log("Query called in removeTag");
        const q = query(highlightsCollection, where("owner", "==", userID), where("paragraphID", "==", paragraphID.toString()), where("bookID", "==", bookID));
    
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            console.log("No matching highlights found");
            return;
        }
        
        querySnapshot.forEach(async (doc) => {
            await updateDoc(doc.ref, {
                tags: arrayRemove(tagToRemove)
            });
        });
    
        // Update the local state
        setTagList(prev => prev.filter(tag => tag !== tagToRemove));
    };
    

    const showTags = () => {
        const viewWidth = window.innerWidth;
        let tagsToShow = [];
        let currentLength = 0;
        let additionalTagsCount = 0;
        let displayNum = "";

        if (viewWidth < 295) {
            displayNum = (tagList.length > 0) ? tagList.length : false;
            tagsToShow = displayNum ? [displayNum] : [];
        }
        else if (viewWidth < 414) {
            displayNum = (tagList.length > 0) ? tagList.length + " tag" + (tagList.length > 1 ? "s" : "") : false;
            tagsToShow = displayNum ? [displayNum] : [];
        } else {
            const CHARACTER_LIMIT_PERCENTAGE = (viewWidth <= 600) ? 0.01 : 0.03;

            const characterLimit = Math.floor(viewWidth * CHARACTER_LIMIT_PERCENTAGE);



            let additionalTagsAdded = false;

            tagList.forEach(tag => {
                if (additionalTagsAdded) {
                    // Skip adding any more tags once "and X more" is added
                    additionalTagsCount += 1;
                    return;
                }

                if (currentLength + tag.length <= characterLimit) {
                    tagsToShow.push(tag);
                    currentLength += tag.length;
                } else {
                    additionalTagsCount += 1;
                    additionalTagsAdded = true; // Ensure no more tags are added
                }
            });
        }

        return (
            <>
                { tagsToShow.map((tagName, index) => (
                    <span key={ index } className="tag-text" data-paragraph-id={ paragraphID } style={{userSelect: "none"}}>{ tagName }</span>
                )) }
                { additionalTagsCount > 0 && (
                    <span className="tag-text">{`${ additionalTagsCount } tag${additionalTagsCount > 1 ? "s" : ""}`}</span>
                ) }
            </>
        );
    };

    return (
        <div className="tag-manager-wrapper">
            { showTags() }
            <Popup trigger={ <span className="deleteHighlight" onClick={ () => setDeleteHighlightConfirm(!deleteHighlightConfirm) }><DeleteIcon /></span> } position="top">
                <div className="popup delete-highlight-popup">
                    <button onClick={ () => { handleHighlightDelete(paragraphID) } }>DELETE HIGHLIGHT</button> <br /><br />
                </div>
            </Popup>




            <Popup trigger={ <span className="addNote"><CreateIcon /></span> } onClose={ saveNote } position="top">
                <div className="popup add-note-popup">
                    <h2>Note</h2>
                    <textarea rows="5" cols="40" id="TITLE" onChange={ (e) => setNoteInput(e.target.value) }>{ noteInput }
                    </textarea><br /><br />
                </div>
            </Popup>

            <span className="tag-text add-tag" data-paragraph-id={paragraphID} onClick={handleOpenManageTagModal}><LocalOfferIcon /></span>
        


        <Dialog open={openManageTagModal} onClose={handleCloseManageTagModal}>
        <div className="dialog manageTagsPopup">
            <h2>Manage Tags</h2>
            <div className="manageTagsText">"{highlightedText.length > 200 ? `${highlightedText.substring(0, 200)}...` : highlightedText}"</div>
            <div className="tag-searchbox">
                <LocalOfferIcon style={{ position: "absolute", color: "#ccc", marginTop: "4px", marginLeft: "4px" }} />
                <input
                    type="text"
                    placeholder="Add new tag"
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleTagAdd()}
                    style={{ textIndent: "25px" }}
                />
            </div>
            <div className="manageTagsPopupList">
                <span className="manageTagsLabel">{tagList.length > 0 ? <>Current Tags<br /></> : ""}</span>
                {tagList.map((tag, index) => (
                    <span className="tagListItem" key={index}>
                        {tag} <button className="tagListXButton" onClick={() => removeTag(tag)}>X</button>
                    </span>
                ))}
            </div>
        </div>
    </Dialog>
        </div>
    );
};

export default TagManager;
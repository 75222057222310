// src/components/dashboard/Dashboard.js

import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../../components/shared/firebase';
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { checkSubscription } from './utility/condenser';
import { Box } from '@mui/material';
import "../../assets/styles/Dashboard.css";
import "../../assets/styles/Account.css";


import PricingTable from './PricingTable';
import StripeManageSubscriptionLink from './StripeManageSubscriptionLink';


const Account = () => {
  const [user, setUser] = useState(null); // Replace with actual auth logic
  const [userID, setUserID] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [gotSubInfo, setGotSubInfo] = useState(false)

  const navigate = useNavigate();

  const menuRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in
        setUser(firebaseUser);
        setUserID(firebaseUser.uid);
  
        checkSubscription(firebaseUser.uid).then(subCheck => {
          setSubActive(subCheck)
          setGotSubInfo(true)
        })
      } else {
        // User is signed out
        setUser(null);
        setUserID(null);
        setSubActive(false); // Ensure subscription status is reset when user signs out
      }
    });
  

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!userID) return;

    const getUserActiveSub = async () => {
      try {
        const userDocRef = doc(db, "users", userID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.subscriptionActive) {
            setSubActive(true);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserActiveSub();
  }, [userID]);


  const handleLogout = () => {
    setTimeout(() => {
      signOut(auth)
        .then(() => navigate("/"))
        .catch((error) => console.error("Logout error:", error));
    }, 100); // Delay logout by 100ms
  };


  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleDashboardClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  const loadComponent = () => (
    <div className="dashboardContainer accountContainer" onClick={ handleDashboardClick }>
    {subActive ? <><div className="user-top-menu-bar"></div>
    <div className="userMenu">
        <div className={ menuVisible ? "menuBarContainer activeMenu" : "menuBarContainer" } onClick={ handleMenuClick }>
          <div className="menuBar"></div>
          <div className="menuBar"></div>
          <div className="menuBar"></div>
        </div>

      { menuVisible ? (
        <div ref={ menuRef } className="floatingMenuContainer">
          <div className="floatingMenuItem" onClick={ () => { navigate("/"); setMenuVisible(false) } }>Library</div>
          <div className="floatingMenuItem" onClick={ () => { navigate("/highlights"); setMenuVisible(false) } }>Highlights</div>
          <div className="floatingMenuItem subscription active-page" onClick={ () => { navigate("/account"); setMenuVisible(false) } }>Manage Subscription</div>
          <div className="floatingMenuItem" onClick={ () => { navigate("/help"); setMenuVisible(false) } }>Help</div>
          <div className="floatingMenuItem logout" onClick={ handleLogout }>Logout</div>
        </div>
      ) : "" }
    </div></> : "" }
      <div className="accountContainer">
        <div className="currentSubscription">
          <div className="subActiveText">{!subActive ? 
            <div className="subInactiveSection">
              { user ? <>Logged in as <strong>{user.email}</strong> (<Link onClick={handleLogout}>Logout</Link>)<br />
              </> : "" }
              {user  ? 
              <>
                <PricingTable 
                  title="Subscribe Below to Continue"
                  subtitle="If Condenser isn't exactly what you'd hoped it would be, contact us within 60 days of your purchase. We'll refund you in full." 
                  email={user.email} 
                />
                <h2>What's Included?</h2>
                <div className="included-features">
                  <ul>
                    <li>
                    <strong>Full Access to the Condenser Financial News App on Your Phone, Laptop, Tablet, and Almost Any Device With an Internet Connection</strong>
                    </li>
                    <li>
                    <strong>Latest Financial News Every Trading Day, Filtered For Just the Concrete Facts You Want</strong>
                    </li>
                    <li>
                    <strong>Save Potentially Hundreds of Hours Each Year</strong>, Without Sacrificing Key Market Knowledge
                    </li>
                    <li>
                    Retain Far More of What You Read by <strong>Highlighting, Taking Notes, and Reviewing</strong> Your Personal Library of Saved Facts
                    </li>
                    <li>
                      Study Inside Condenser or <strong>Export Saved Facts</strong> to Outside Platforms Including Word, Google Docs, Excel, Google Sheets, Readwise, and Markdown
                    </li>
                    <li>
                      60% Cheaper Than ChatGPT
                    </li>
                  </ul>
                  <button className="learnMoreAccount" onClick={()=>{window.scrollTo(0,0); navigate("/letter")}}>See More Details</button>
                  <Box sx={{ height: '200px', mt: '20px' }}>
                  </Box>
                </div>
              </> : "" }
              </div> : 
            <div className="subActiveSection">
            { user ? <span className="loggedInAs">Logged in as <strong>{user.email}</strong> (<Link onClick={handleLogout}>Logout</Link>)</span> : "" }
            <div className="subStatusWrapper">
            <div className="yourSubIs">
            Your Subscription Is</div><br />
              <div className="activeText">Active</div>
              </div>
              {user ? <StripeManageSubscriptionLink userEmail={user.email} text="Manage Your Subscription with Stripe" button /> : "" }
              <div className="forSupport">For help, please contact us at <strong>support@twofivelabs.com</strong> or <a rel="noreferrer" target="_blank" href="https://contact.twofivelabs.com">click here to create a support ticket</a>.</div>
            </div>}</div>
        </div>
      </div>
    </div>
  )

  return (
    gotSubInfo && loadComponent()
  )
}

export default Account;

import React from 'react'

const PricingTable = ({title, subtitle, email}) => (
    <div className="pricingTable">
        <h2 style={{fontSize: "3em"}}>{title}</h2>
        <h3 style={{fontWeight: 100}}>{subtitle}</h3>
        <div className="subPlans">
        <stripe-pricing-table pricing-table-id="prctbl_1Ov5uUKKt1ZuMu1h8UokYKkF"
        publishable-key="pk_live_51Mz3KxKKt1ZuMu1hoK1W7k1lJOmOSpZ7uHYziT4bfXNbJv5xVOH8Bl2eQxhbdBd6hMw1h2fFCP6BkBSvCGM1wPAV00kw70vKrJ">
        </stripe-pricing-table>
        </div>
    </div>
)

export default PricingTable